import React from 'react';
import ReferenzenAdmin from '../components/referenzen/index-admin';
import Layout from '../components/layout'
import { graphql } from "gatsby"


const Referenzen_ = ({ data }) => {
  return (
    <Layout title="Referenzen Auswahl">
      <ReferenzenAdmin data={data} admin={true} />
    </Layout>
  )
}
export const query = graphql`
{
    allProject(sort: {fields: [konstruktionReihenfolge], order: ASC}) {
      edges {
        node {
          id
          titelLagebeschreibung
          beschreibung
          konstruktionsVariante
          konstruktionReihenfolge
          sonderkonstruktion
          gelander
          beschichtung
          entwasserungebene
          konstruktionsVariante
          fussboden
          projektgrosse
          ort
          slug
          path
          projekt
          teaser {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  
`

export default Referenzen_