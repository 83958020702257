import React, { Component } from 'react'

import SubPage from '../subPage'

import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text, Card } from '@rebass/emotion'
import { css } from '@emotion/react'
import debounce from 'lodash/debounce'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


class ReferenzenAdmin extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            focused: false,
            projects: [],
            value: props.term,
        }
    }

    onSearchChange = debounce(() => {
        this.props.onSearchChange(this.state.value)
    }, 500)

    onChange = (ev) => {
        this.setState({
            value: ev.target.value
        }, () => {
            this.onSearchChange()
        })
    }

    onAddProject = (project) => {
        if (!this.state.projects.includes(project)) {
            this.setState({
                projects: [...this.state.projects, project],
            }, () => {
                console.log(this.state.projects)
            })
        }
        else {
            this.setState({
                projects: [...this.state.projects, project].filter(i => i !== project),
            }, () => {
                console.log(this.state.projects)
            })
        }
    }

    componentDidMount() {
        if (this.props.search && this.props.term) {
            this.myRef.current.focus()
            this.props.onLeaveHeaderSearch()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.search && this.props.term) {
            if (prevProps.term !== this.props.term) {
                this.myRef.current.focus()
                this.props.onLeaveHeaderSearch()
            }
        }
    }

    render() {
        const edges = this.props.data.allProject.edges

        return (
            <SubPage>
                <Box py={[20, 0]} mb={[50, 100]}>
                    <Box width={1}>
                        <input onClick={(ev) => {
                            var copyText = ev.target;

                            /* Select the text field */
                            copyText.select();
                            copyText.setSelectionRange(0, 99999); // For mobile devices

                            /* Copy the text inside the text field */
                            navigator.clipboard.writeText(copyText.value)
                                .then(() => {
                                    // i need an effect on a input which indicates some background actvitiy

                                    copyText.style.backgroundColor = '#eee';
                                    setTimeout(() => {
                                        copyText.style.backgroundColor = '';
                                    }, 250);
                                    console.log('Text copied to clipboard');
                                })
                                .catch(err => {
                                    console.error('Failed to copy text: ', err);
                                });
                        }}
                            readOnly
                            value={`https://balkonsysteme.info/referenzen-auswahl/?f=${encodeURIComponent(this.state.projects.join(","))}`}
                            style={{ width: "90%", textAlign: 'center', fontSize: '14px' }} type="text" />
                    </Box>
                    <Box mt={[20, 50]} py={[20, 50]} css={{
                        backgroundColor: '#F3F5F6',
                    }}>
                        <Box m='auto' width={[0.9, 0.9, 0.9, 1]} css={{
                            maxWidth: '1250px',
                            zIndex: 1,
                            position: 'relative'
                        }}>
                            <Flex flexWrap='wrap'>
                                {edges.map(({ node }) =>
                                    <Box key={node.id} width={[1 / 1, 1 / 2, 1 / 3]}>
                                        <Card mx={[0, 10]} my={15}
                                            borderRadius={5}>

                                            <div style={{
                                                backgroundColor: '#064360'
                                            }} onClick={() => {
                                                console.log(node.projekt)
                                                this.onAddProject(node.projekt)
                                            }}>
                                                <Box css={[{ overflow: 'hidden', borderRadius: 5 }]}>
                                                    <Box css={{
                                                        position: 'relative',
                                                        height: 0,
                                                        paddingBottom: '65%',
                                                        overflow: 'hidden'
                                                    }}>

                                                        {node && node.teaser && <GatsbyImage image={getImage(node.teaser.file)} alt={node.projekt + " " + node.beschreibung} />}
                                                    </Box>
                                                    <Box py={[20, 20, 20, 30]} px={30} css={css`
                                                        background-color: ${this.state.projects.includes(node.projekt) ? '#c5e6cc' : '#fff'};

                                                        @media (min-width: 32em) {
                                                            min-height: 200px;
                                                        }

                                                        @media (min-width: 56em) {
                                                            min-height: 240px;
                                                        }

                                                        @media (min-width: 74em) {
                                                            min-height: 280px;
                                                        }
                                                        
                                                    
                                                    `}>
                                                        <Heading
                                                            mt={0}
                                                            mb={[10, 10, 10, 20]}
                                                            color="#064360"
                                                            fontWeight={400}
                                                            fontSize={[20, 20, 22, 30]}>
                                                            {node.titelLagebeschreibung}
                                                        </Heading>
                                                        <Text fontSize={16} lineHeight={1.8} color="#064360">
                                                            <div>{node.projekt}</div>
                                                            <div>{node.beschreibung}</div>
                                                        </Text>

                                                        <Box mt={10}>
                                                            <Text color="#555" fontWeight={400} lineHeight="1.6em" fontSize={[10, 10, 12, 14]} textAlign="left">
                                                                {['konstruktionsVariante', 'gelander', 'beschichtung', 'entwasserungebene', 'fussboden']
                                                                    .filter(key => node[key])
                                                                    .map((key, idx, list) =>
                                                                        <span key={key}>{node[key]}{idx < (list.length - 2) ? ' - ' : ''}</span>
                                                                    )}
                                                            </Text>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </div>
                                        </Card>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </SubPage >
        )
    }
}

export default ReferenzenAdmin
